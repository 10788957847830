<template>
  <div>
    <v-divider :dark="tenant.toolbarDark" />

    <v-container
      fluid
      class="d-flex flex-row align-start pa-0 align-stretch event-container"
    >

        <div v-if="showNotFoundOrNoPermissionsError" class="content-container mt-16">
          <v-alert type="warning" prominent icon="fa-lock">
            <h3>Event not found or No Permissions to manage</h3>
            <p>Either the event does not exit or you do not have permissions to manage it. Please make sure you are logged in with the correct account.</p>
            <p class="mt-8 mb-0">Id:</p>
            <code>{{$route.params.id}}</code>
            <p class="mt-8">
              <v-btn :to="{ name: 'eventmanager'}">My Events</v-btn>
            </p>
          </v-alert>
        </div>
        <div v-if="!showNotFoundOrNoPermissionsError" class="grey lighten-4 pa-0 content-container">
            <v-toolbar dense v-if="event && eventUtil.newLayout" elevation="0" color="white lighten-4 width-100p" >
            <v-toolbar-title class="ml-4"><router-link class="no-decoration" :to="{name:'eventmanagerView', params: {id: event.id}}">{{event.name}}</router-link></v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
              <v-btn v-if="event" outlined :to="{name:'eventmanagerView', params: {id: event.id}}" class="mr-2">
                <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
                Back-end
              </v-btn>
              <v-btn v-if="event && event.published" outlined :to="{name:'event', params: {id: event.id}}" class="mr-2">
                <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
                Public
              </v-btn>
            </div>
            </v-toolbar>
            <v-divider v-if="eventUtil.newLayout"/>
            <v-alert v-if="event && event.cleaned_date" tile type="warning" class="mb-0">
              <p>This event was cleaned-up on {{ event.cleaned_date | localDate('yMd') }}. All results and user data have been deleted.</p>
            </v-alert>

            <v-card class="ma-4 pa-0">
              <router-view  :tenant="tenant" :event="event"></router-view>
            </v-card>
        </div>
    </v-container>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventManagerService from "@/services/eventManagerService";
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventLayoutBase",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      siteData: siteData,
      showNotFoundOrNoPermissionsError: false,
    };
  },
  async mounted() {
    this.mini = this.$cookies.isKey('em.menu.mini');
    this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      this.loadData();
    });
    
    EventBus.$on('eventmanager-request-save', async () => {
      const saved = await this.eventUtil.save();
      if (saved) {
        this.event = this.eventUtil.event; // force refresh down the tree
      }
    });
    EventBus.$on('eventmanager-refresh', async state => {
        console.log('refresh requested', state);
        this.event = state.event; // force refresh down the tree
    });
    EventBus.$on('http-error', async response => {
        if (response.status == 401) {
          this.$helpers.toastResponse(this, { status: "ERROR", msg: "Unauthorized: no permissions to complete this request."});
        }
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },
    async getEvent(id) {
      try {
        this.event = (await eventManagerService.get(id)).data;
        this.showNotFoundOrNoPermissionsError = false;
      }
      catch {
        this.showNotFoundOrNoPermissionsError = true;
      }
    },
   
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss">
    .width-100p { width: inherit !important;}
    .event-container {
      width: inherit !important;

      .content-container {
        flex-grow: 1;
        min-width: 400px;
      }
    }
    .info-container {
        .label { font-size: 80%; opacity: .8; }
        .value { margin-bottom: 10px; }
        .value pre { white-space: pre-wrap;}
        /*.value:last-child { margin-bottom: 0px; ;}*/
    }

</style>

